import './App.css';

function App() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh', flexDirection: 'column'}}>
      <h1>Forty Acres Capital</h1>
      <h4>Coming Soon</h4>
    </div>
  );
}

export default App;
